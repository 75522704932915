import "react-dates/initialize";

import Router from "next/router";
import { Fuego, FuegoProvider } from "swr-firebase";

import { AuthProvider } from "@contexts/auth";
import { DataProvider } from "@contexts/data";
import { FeedbackModalProvider } from "@contexts/feedback";
import { ThemeProvider } from "@contexts/theme";
import analytics from "@lib/analytics";
import { datadogRum } from "@lib/datadog-rum";
import { compatDB as db } from "@lib/firebase-config";

import { ActionDialogProvider } from "@components/ActionDialogProvider";
import FacebookPixel from "@components/FacebookPixel";
import { SiteLayout } from "@components/SiteLayout";
import SnackbarProvider from "@components/SnackbarProvider";

import "../styles/tailwind.css";
import "../styles/index.css";
import "../styles/tailwind-utils.css";
import "react-dates/lib/css/_datepicker.css";
import "../styles/react_dates_overrides.css";
import "../styles/quill.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../styles/fullcalendar_overrides.css";
import "../styles/hubspot.css";

// TODO: We have a problem where we maintain our own instance
// of the firebase-admin config as well as the instance that
// fuego tries to make. The code says it should fall back
// to the instance we've created but that isn't he case in practice.
// We'll need to refactor this library.
const fuego = new Fuego({
  projectId: "hack",
});
fuego.db = db;
Router.events.on("routeChangeComplete", () => {
  analytics.track("screen_view");
});

// This can only happen once!
datadogRum.startSessionReplayRecording();

const App = ({ Component, pageProps, err, router }) => {
  if (router.pathname === "/refresh-auth") return <Component {...pageProps} />;

  return (
    <FacebookPixel>
      <FuegoProvider fuego={fuego}>
        <AuthProvider>
          <DataProvider>
            <SnackbarProvider>
              <FeedbackModalProvider>
                <ActionDialogProvider>
                  <ThemeProvider>
                    <SiteLayout {...pageProps}>
                      <Component {...pageProps} err={err} />
                    </SiteLayout>
                  </ThemeProvider>
                </ActionDialogProvider>
              </FeedbackModalProvider>
            </SnackbarProvider>
          </DataProvider>
        </AuthProvider>
      </FuegoProvider>
    </FacebookPixel>
  );
};

export default App;
